<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Sort each of the following elements in order of
        <span class="text-bold">increasing radius</span>
        (i.e. smallest to largest). Please enter the letter corresponding to each atom/ion in the
        answer fields.
      </p>
      <!--      TODO: I want to decrease the height of the input fields and center the text within the input fields-->
      <p class="pl-8 mb-1">
        <stemble-latex :content="'$\\text{A. }' + atom1 + '$'" />
      </p>
      <p class="pl-8 mb-1">
        <stemble-latex :content="'$\\text{B. }' + atom2 + '$'" />
      </p>
      <p class="pl-8 mb-1">
        <stemble-latex :content="'$\\text{C. }' + atom3 + '$'" />
      </p>
      <p class="pl-8 mb-1">
        <stemble-latex :content="'$\\text{D. }' + atom4 + '$'" />
      </p>
      <p class="pl-8 mb-4">
        <stemble-latex :content="'$\\text{E. }' + atom5 + '$'" />
      </p>

      <table style="width: 581px">
        <tr>
          <td style="width: 42px; vertical-align: top">
            <sorting-input v-model="inputs.input1" group-size="sm" :disabled="!allowEditing" />
          </td>
          <td class="less-then-symbol">&lt;</td>
          <td style="width: 42px; vertical-align: top">
            <sorting-input v-model="inputs.input2" group-size="sm" :disabled="!allowEditing" />
          </td>
          <td class="less-then-symbol">&lt;</td>
          <td style="width: 42px; vertical-align: top">
            <sorting-input v-model="inputs.input3" group-size="sm" :disabled="!allowEditing" />
          </td>
          <td class="less-then-symbol">&lt;</td>
          <td style="width: 42px; vertical-align: top">
            <sorting-input v-model="inputs.input4" group-size="sm" :disabled="!allowEditing" />
          </td>
          <td class="less-then-symbol">&lt;</td>
          <td style="width: 42px; vertical-align: top">
            <sorting-input v-model="inputs.input5" group-size="sm" :disabled="!allowEditing" />
          </td>
        </tr>
      </table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import SortingInput from '../inputs/SortingInput';

export default {
  name: 'Question202',
  components: {
    SortingInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    atom1() {
      if (this.versionNumber.value === 1) {
        return '\\text{K}';
      } else if (this.versionNumber.value === 2) {
        return '\\text{Mg}';
      } else if (this.versionNumber.value === 3) {
        return '\\text{F}';
      } else if (this.versionNumber.value === 4) {
        return '\\text{S}';
      } else {
        return '';
      }
    },
    atom2() {
      if (this.versionNumber.value === 1) {
        return '\\text{Li}';
      } else if (this.versionNumber.value === 2) {
        return '\\text{Ca}';
      } else if (this.versionNumber.value === 3) {
        return '\\text{I}';
      } else if (this.versionNumber.value === 4) {
        return '\\text{Te}';
      } else {
        return '';
      }
    },
    atom3() {
      if (this.versionNumber.value === 1) {
        return '\\text{Na}';
      } else if (this.versionNumber.value === 2) {
        return '\\text{Be}';
      } else if (this.versionNumber.value === 3) {
        return '\\text{Cl}';
      } else if (this.versionNumber.value === 4) {
        return '\\text{O}^{2-}';
      } else {
        return '';
      }
    },
    atom4() {
      if (this.versionNumber.value === 1) {
        return '\\text{Rb}';
      } else if (this.versionNumber.value === 2) {
        return '\\text{Be}^+';
      } else if (this.versionNumber.value === 3) {
        return '\\text{Br}';
      } else if (this.versionNumber.value === 4) {
        return '\\text{Se}';
      } else {
        return '';
      }
    },
    atom5() {
      if (this.versionNumber.value === 1) {
        return '\\text{Li}^+';
      } else if (this.versionNumber.value === 2) {
        return '\\text{Sr}';
      } else if (this.versionNumber.value === 3) {
        return '\\text{F}^-';
      } else if (this.versionNumber.value === 4) {
        return '\\text{O}';
      } else {
        return '';
      }
    },
  },
};
</script>
